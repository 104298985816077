import ApiLpc from '@services/http/ApiLpc';
import { axiosClient, getUrl } from '@services/http/Http';

const getAllActivities = (potRef: string, cursor: number, pageSize: number, ctx?: object) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.ACTIVITIES.GET_ALL_ACTIVITIES, potRef, cursor, pageSize))
        .then((res) => res.data);

const getAllActivitiesByPersonID = (potRef: string, personID: number, cursor: number, pageSize: number, ctx?: object) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.ACTIVITIES.GET_ALL_ACTIVITIES_BY_PERSON, potRef, cursor, pageSize, personID))
        .then((res) => res.data);

const addMessage = (potRef: string, body: { message: string }, ctx?: object) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.ADD_MESSAGE, potRef), JSON.stringify(body));

const likeActivity = (potRef: string, uniqPA: string, ctx?: object) =>
    axiosClient(ctx).post(getUrl(ApiLpc.ACTIVITIES.LIKE_ACTIVITY, potRef, uniqPA));

const unlikeActivity = (potRef: string, uniqPA: string, ctx?: object) =>
    axiosClient(ctx).post(getUrl(ApiLpc.ACTIVITIES.UNLIKE_ACTIVITY, potRef, uniqPA));

const deleteActivity = (potRef: string, uniqPA: string, ctx?: object) =>
    axiosClient(ctx).delete(getUrl(ApiLpc.ACTIVITIES.DELETE_ACTIVITY, potRef, uniqPA));

const checkOsflmUserInfo = (body, ctx?: object) => axiosClient(ctx).post(getUrl(ApiLpc.OSFLM.VALIDATE_USER_INFO), body);
const validateOsflmForm = (body, ctx?: object) => axiosClient(ctx).post(getUrl(ApiLpc.OSFLM.VALIDATE_FORM), body);

const startTutorial = (ctx?: object) => axiosClient(ctx).get(getUrl(ApiLpc.TUTORIAL));

const confirmKycInfosAreUpToDate = (ctx?: object) => axiosClient(ctx).post(getUrl(ApiLpc.KYC.VALIDATE));

const updateEmail = (uniqUP: string, ctx?: object) => {
    return axiosClient(ctx).put(getUrl(ApiLpc.AUTH.EMAIL_CHANGE, uniqUP));
};

const saveUserAction = (potRef: string, action: string, ctx?: object) =>
    axiosClient(ctx).post(getUrl(ApiLpc.GREETING_CARD.SAVE_ACTION), JSON.stringify({ uniqPO: potRef, action: action }));

const notificationService = {
    addMessage,
    saveUserAction,
    getAllActivities,
    getAllActivitiesByPersonID,
    likeActivity,
    unlikeActivity,
    deleteActivity,
    confirmKycInfosAreUpToDate,
    updateEmail,
    startTutorial,
    checkOsflmUserInfo,
    validateOsflmForm,
};

export default notificationService;
